import React from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { getAssetUrl } from "../../../../helpers/general";

const UserChoise = () => {
  const {
    data,
    isLoading,
    error: isError,
  } = useAxiosSWR("/v1/misc/userChoice");
  const userChoiseData = data || [];
  if (isLoading) {
    return (
      <div className="max-w-[1320px] h-16 bg-gray-100 loading mx-auto mt-4"></div>
    );
  }
  if (isError) {
    return <h2></h2>;
  }
  return (
    <div className="">
      <div className="flex md:items-center">
        <div className="md:block hidden md:pl-6 px-2 text-[25px] font-bold">Trending</div>
        <img src={getAssetUrl('fireball.svg')} alt="Fire Ball" className="md:block hidden h-[60px] w-[60px]"/>
        {userChoiseData.map((obj, i) => (
          <a href={obj.url} target="_blank" key={i} className={`md:bg-[#E8F1FF80] md:p-2 text-xs rounded-xl md:mx-2 w-full`}>
            <img className={`md:h-[49px] md:w-full h-10 w-10 md:rounded-2xl rounded-full object-cover mx-auto`} src={obj.icon} alt="current series" />
            <p className={`font-medium md:text-sm text-[10px] leading-3 md:leading-0 pt-2 text-center text-wrap`}>{obj.title}</p>
        </a>
        ))}
      </div>
    </div>
  );
};

export default UserChoise;
